import { Component } from "react";
import {
    Modal, ModalBody, ModalFooter, ModalHeader,
    Input, Button, InputGroup, Table, FormFeedback
} from 'reactstrap'
import { BsSearch } from 'react-icons/bs'
import InputMask from 'react-input-mask';
import { TbCalendarOff } from 'react-icons/tb'
import CharacterRemover from 'character-remover';
import axios from "axios";
import { URL_Scheduling } from "../Services/schedulingService";
import {
    format, parseISO, subMinutes
} from 'date-fns'
import { FaSpinner } from 'react-icons/fa';
import swal from 'sweetalert';
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';
import { URL_PreScheduling } from "../Services/preSchedulingService";
import { MdPayments, MdDoNotDisturbOn } from 'react-icons/md'
import { URL_DigitalPayment } from "../Services/digitalPaymentService";
import { GiConfirmed } from 'react-icons/gi'
import { useNavigate, } from 'react-router-dom';
import { auth } from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber, signOut } from "firebase/auth";
import ReactDOM from 'react-dom';
import { Spinner } from "../../common/components/spinner/spinner";


export default class SchedulingsSearch extends Component {
    constructor(props) {
        super(props)
        const myParam = window.location.pathname.split('/')[1]
        const myParamTel = window.location.pathname.split('/')[3]

        this.state = {
            listSchedulings: {
                results: [
                ], currentPage: 0, pageCount: 0, pageSize: 10, rowCount: 0, firstRowOnPage: 0, lastRowOnPage: 0
            },
            formFilter: { cellPhoneOption: myParamTel, pageNumber: 1, pageSize: 10 },
            errors: {},
            loading: false,
            loadingModal: false,
            loadUncheck: false,
            idScheduling: 0,
            myParam: myParam,
            modelClinic: {},
            paylink: '',
            myParamTel: myParamTel,
            paymentRequired: false,
            payment: false,
        }
    }


    genereteRecaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
            }
        });
    }


    // requestOTP = async () => {
    //     if (this.validate() == 0) {
    //         const { formFilter } = this.state;
    //         let phone = CharacterRemover.removeAll(formFilter.cellPhoneOption);
    //         this.genereteRecaptcha();
    //         let appVerifier = window.recaptchaVerifier;
    //         this.setState({ loadingModal: true })
    //         console.log(phone)
    //         await signInWithPhoneNumber(auth, `+55${phone}`, appVerifier)
    //             .then((confirmationResult) => {
    //                 // SMS sent. Prompt user to type the code from the message, then sign the
    //                 // user in with confirmationResult.confirm(code).
    //                 window.confirmationResult = confirmationResult;
    //             }).catch((error) => {

    //             });
    //         this.setState({ loadingModal: false })
    //         this.toggle();
    //     }
    // }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    verifyOtp = () => {
        let confirmationResult = window.confirmationResult;
        confirmationResult.confirm(this.state.code).then((result) => {
            const user = result.user;
            this.toggle();
            this.searchScheduling();
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
            swal({
                text: "Realize a consulta novamente para receber um novo código!",
                title: "Código expirado ou inválido!"
            })
        });
    }

    searchScheduling = async () => {
        if (this.validate() == 0) {
            const { formFilter, myParam } = this.state
            this.setState({ loading: true })
            await axios.get(`${URL_Scheduling}/GetByPhone`, {
                params: {
                    cellPhoneOption: CharacterRemover.removeAll(formFilter.cellPhoneOption),
                    pageNumber: 1,
                    pageSize: 10,
                    myParam: myParam
                }
            }).then(resp => {
                const { data } = resp;
                this.setState({ listSchedulings: data, loading: false })
            }).catch((er) => { this.setState({ loading: false }) })
        }
    }

    componentDidMount = async () => {
        await this.consultClinic();
        await this.searchScheduling();
    }

    consultClinic = async () => {
        const { myParam } = this.state
        if (myParam != '') {
            await axios.get(`${URL_PreScheduling}/GetClinicById/${myParam}`).then(resp => {
                const { data } = resp
                if (data != 'Clínica não localizada!')
                    this.setState({
                        modelClinic: data,
                        paymentRequired: data.paymentRequired,
                        payment: data.payment,
                        loadingClinic: false
                    })
            })
        }
    }

    consultPayLink = async (id) => {
        const { myParam } = this.state
        if (id != '') {
            await axios.get(`${URL_DigitalPayment}/getlink`, {
                params: {
                    schedulingid: id,
                    myParam: myParam
                }
            }).then(async resp => {
                const { data } = resp
                if (data.status == null) {
                    window.open(data.paymentUrl, '_blank', 'noopener,noreferrer')
                } else if (data.status == "created") {
                    swal({ title: "Aguardando o pagamento!", icon: 'warning', buttons: ["Cancelar", "Ir para a tela"], }).then(e => {
                        if (e) {
                            window.open(data.paymentUrl, '_blank', 'noopener,noreferrer')
                        }
                    })
                }
                else if (data.status == "paid") {
                    swal({ title: "Pagamento foi efetuado com sucesso!", icon: 'success' }).then(resp => {
                        if (resp) {
                            this.searchScheduling();
                        }
                    })
                } else if (data.status == "expired") {
                    swal({ title: "Período expirado para pagamento!", icon: 'warning' })
                }
            })
        }
    }

    searchSchedulingByPagination = async (pageSizeValue, pageNumber) => {
        let pageSize = ''
        if (pageSizeValue.target != undefined)
            pageSize = pageSizeValue.target.value;
        else
            pageSize = pageSizeValue;
        if (this.validate() == 0) {
            const { formFilter, myParam } = this.state
            this.setState({ loading: true })
            await axios.get(`${URL_Scheduling}/GetByPhone`, {
                params: {
                    cellPhoneOption: CharacterRemover.removeAll(formFilter.cellPhoneOption),
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                    myParam: myParam
                }
            }).then(resp => {
                const { data } = resp
                this.setState({ listSchedulings: data, loading: false })
            }).catch(() => { this.setState({ loading: false }) })
        }
    }

    setValues = (field, e) => {
        const { formFilter } = this.state
        formFilter[field] = e.target.value
        this.setState({ formFilter })
    }

    validate = () => {
        const { formFilter } = this.state
        const errors = {}
        let isError = 0;
        let phone = CharacterRemover.removeAll(formFilter.cellPhoneOption)

        if (!phone || phone.length < 11) {
            isError++
            errors.cellPhoneOptionError = true;
        } else
            errors.cellPhoneOptionError = false;

        this.setState({
            errors: errors
        });
        return isError;
    }

    unCheck = async (e) => {
        let map = {
            id: e,
            status: 4//desmarcou
        }
        swal({ title: 'deseja realmente desmarcar?', icon: 'warning', buttons: true }).then(resp => {
            if (resp) {
                this.setState({ loadUncheck: true, idScheduling: e })
                axios.put(`${URL_Scheduling}/ChangeStatusViaSchedulingLink`, map).then(resp => {
                    const { data } = resp
                    if (data) {
                        swal({ title: "Agendamento Desmarcado!", icon: 'warning' })
                        this.setState({ loadUncheck: false, idScheduling: 0 })
                        this.searchScheduling();
                    }
                }).catch(() => { this.setState({ loadUncheck: false, idScheduling: 0 }) })
            }
        })
    }

    toDefineStatus(status) {
        switch (status) {
            case 0:
                return "Agendado"
            case 2:
                return "Atendido"
            case 5:
                return "Particular"
            case 6:
                return "Chegou"
            case 7:
                return "Confirmado"
            case 1:
                return "Cancelado"
            case 3:
                return "Faltou"
            case 4:
                return "Desmarcou"
            default:
                break;
        }
    }
    closeModal = () => {
        if (window.recaptchaVerifier && this.recaptchaWrapperRef) {
            window.recaptchaVerifier.clear()
            this.recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
        }
        this.toggle();
    }

    render() {
        const { listSchedulings, errors, loading,
            loadUncheck, idScheduling, modelClinic, myParam,
            paymentRequired, payment, loadingModal } = this.state
        const { results, currentPage, pageSize, rowCount } = listSchedulings;
        return (
            <div className="App-2" >
                {loadingModal && <Spinner />}
                <div ref={ref => this.recaptchaWrapperRef = ref}>
                    <div id="recaptcha-container"></div>
                </div>

                <Modal isOpen={this.state.modal}
                    toggle={this.toggle}
                    backdrop={'static'}>
                    <ModalHeader
                        toggle={this.closeModal}
                    >Validação de segurança</ModalHeader>
                    <ModalBody>
                        Insira o código recebido em seu celular!
                        <Input
                            mask='999999'
                            tag={InputMask}
                            type="text"
                            onChange={e => this.setState({ code: e.target.value })}
                        >
                        </Input>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={e => this.verifyOtp()}>Ok</Button>
                    </ModalFooter>
                </Modal>
                <div className='w-100 mh-100 card'>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <InputGroup>
                                    <Input
                                        mask='(99) 9 9999-9999'
                                        tag={InputMask}
                                        placeholder="Número do seu celular"
                                        onChange={e => this.setValues('cellPhoneOption', e)}
                                        invalid={errors.cellPhoneOptionError}
                                    />
                                    <Button color="primary"
                                        onClick={e => this.searchScheduling()}>
                                        <BsSearch />
                                    </Button>
                                </InputGroup>
                                <FormFeedback></FormFeedback>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            {loading ? <div className="text-center mb-4"><FaSpinner color="#0000FF" size={30} className="icon_pulse" /></div> :
                                <Table responsive
                                    striped size="sm">
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                Nome
                                            </th>
                                            <th>
                                                Data
                                            </th>
                                            <th>Profissional</th>
                                            <th>Status</th>
                                            <th>Valor</th>
                                            <th>Pagamento</th>
                                            <th>
                                                Opções
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {results.map((e, index) => (
                                            <tr key={index} >
                                                <th scope="row">
                                                    {index}
                                                </th>
                                                <td>
                                                    {e.name}
                                                    {e.services.map((e, index) => (<li key={index}>{e.nameService}</li>))}
                                                    {e.packages.map((e, index) => (<li key={index}>{e.name}</li>))}
                                                </td>
                                                <td>
                                                    {format(parseISO(e.date, new Date()), 'dd-MM-yyyy HH:mm')}
                                                </td>
                                                <td>{e.professional}</td>
                                                <td>{this.toDefineStatus(e.status)}</td>
                                                <td>
                                                    {e.services.reduce((partialSum, a) => partialSum + a.value, 0)
                                                        .toLocaleString('pt-br',
                                                            { style: 'currency', currency: 'BRL' })}
                                                </td>
                                                <td >
                                                    {e.paymentConfirmed ?
                                                        <GiConfirmed size={20} color="green" /> :
                                                        <MdDoNotDisturbOn size={20} color="red" />
                                                    }
                                                </td>
                                                <td>
                                                    <Button
                                                        disabled={
                                                            e.status == 1 || e.status == 3 || e.status == 4 ? true :
                                                                subMinutes(parseISO(e.date, new Date()), modelClinic.uncheckSchedule) > new Date() ? false :
                                                                    true}
                                                        size="sm"
                                                        color={e.status == 1 || e.status == 3 || e.status == 4 ? 'secondary' :
                                                            subMinutes(parseISO(e.date, new Date()), modelClinic.uncheckSchedule) > new Date() ? 'danger' : "secondary"}
                                                        title="Desmarcar"
                                                        onClick={x => this.unCheck(e.idScheduling)}
                                                    >
                                                        {loadUncheck && e.idScheduling == idScheduling && (
                                                            <FaSpinner className=" icon_pulse" />
                                                        )}

                                                        {e.idScheduling != idScheduling && <TbCalendarOff size={25} />}
                                                    </Button>
                                                    {' '}
                                                    {/* {paymentRequired === true && e.services.length > 0 ?
                                                        <Button
                                                            //desabilitar se não tiver serviço
                                                            color="success"
                                                            size="sm"
                                                            title="Pagamento"
                                                            onClick={x => this.consultPayLink(e.idScheduling)}
                                                        >
                                                            <MdPayments size={25} />
                                                        </Button> : null} */}
                                                    {' '}
                                                    {e.services.length > 0 && e.paymentConfirmed == false
                                                        && (payment == true || paymentRequired == true) ?
                                                        <RedirectPayment
                                                            e={e}
                                                            myParam={myParam} /> : null}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>}
                        </div>
                        <div className="row">
                            <Pagination
                                {...bootstrap5PaginationPreset}
                                current={currentPage}
                                total={Math.round(rowCount / 10, 1) + 1}
                                onPageChange={(pageNumber) => this.searchSchedulingByPagination(pageSize, pageNumber)}
                                maxWidth={40}
                                narrowStrategy={['dropEllipsis', 'dropNav']}
                            />
                        </div>
                    </div>
                </div>
            </div>)
    }
}

function RedirectPayment(props) {
    const navigate = useNavigate()
    const val = () => {
        const tel = CharacterRemover.removeAll(props.e.telephone)
        let map = {
            name: props.e.name,
            telephone: tel,
            preSchedulingServiceProvideds: props.e.services,
            document: CharacterRemover.removeAll(props.e.document),
            posScheduling: true,
            idScheduling: props.e.idScheduling
        }
        localStorage.setItem('mapscheduling', JSON.stringify(map))
        navigate('/' + props.myParam + '/formpayment', { replace: false })
    }

    return (
        <Button
            color="success"
            size="sm"
            title="Pagamento"
            onClick={x => val()}
        >
            <MdPayments size={25} />
        </Button>
    )
}