import React, { Component } from 'react';
import {
    Button,
    Input,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    InputGroup,
    FormGroup
} from 'reactstrap'
import axios from 'axios';
import { URL_PreScheduling } from '../Services/preSchedulingService';
import { BsClockHistory } from 'react-icons/bs'
import { FaSpinner } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';

export default class SelectServices extends Component {
    constructor(props) {
        super(props)
        const myParam = window.location.pathname.split('/')[1];
        this.state = {
            listProcedures: [],
            myParam: myParam,
            navigate: false,
            loading: true,
            nameProcedure: '',
            listChecked: []
        }
    }

    componentDidMount() {
        this.consultProcedures();
    }

    consultProcedures = async () => {
        const { myParam, nameProcedure } = this.state
        await axios.get(`${URL_PreScheduling}/GetProcedures`, {
            params: {
                aliasClinic: myParam,
                nameProcedure: nameProcedure
            }
        }).then(resp => {
            const { data } = resp
            this.setState({ listProcedures: data, loading: false })
        })
    }

    addService = (service, x) => {
        const { listProcedures, listChecked } = this.state

        listProcedures.forEach(element => {
            if (element.id == service.id) {
                element.checked = x.target.checked
                if (x.target.checked) {
                    let map = {
                        idProcedure: element.id,
                        value: element.value,
                        redemptionPoints: element.redemptionPoints,
                        loyaltyPoint: element.loyaltyPoints,
                        duration: element.duration
                    }
                    listChecked.push(map)

                } else {
                    for (var i = 0; i < listChecked.length; i++) {
                        if (listChecked[i].idProcedure === element.id) {
                            listChecked.splice(i, 1);
                        }
                    }
                }
            }
        });
        let listProcedureId = [];
        listChecked.forEach(element => {
            listProcedureId.push(element.idProcedure)
        });
        localStorage.setItem('listProcedureId', JSON.stringify(listProcedureId))
        localStorage.setItem('listProcedures', JSON.stringify(listChecked))
        this.setState({ listProcedures, listChecked: listChecked })
    }

    render() {
        const { listProcedures, myParam, loading, listChecked } = this.state
        return (
            <div>
                {loading ? <div className='text-center mt-5'><FaSpinner color={'blue'} className="icon_pulse" />
                </div> :
                    <div>
                        {listProcedures.length > 0 ?//&& listProcedures.find(e => e.showOnSchedule > 0) ?
                            <div>
                                <Row>
                                    <Col md='4'>
                                        <InputGroup>
                                            <Input
                                                placeholder="Consulta serviço"
                                                onChange={e => this.setState({ nameProcedure: e.target.value })}
                                            >
                                            </Input>
                                            <Button color="primary" onClick={e => this.consultProcedures(e.target.value)}>
                                                <BsSearch />
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className='mt-1'>
                                    {listProcedures.map(e => (
                                        e.showOnSchedule > 0 ?
                                            <Col lg="2"
                                                sm="4"
                                                key={e.id}
                                            >
                                                <Card
                                                    style={{ height: '95%', cursor: 'pointer' }}
                                                    className="card-default shadow p-1 mb-1 bg-white rounded">
                                                    <CardBody
                                                        style={{ padding: '3%' }}
                                                        className="text-center">
                                                        {e.thumbFile != null ?
                                                            <img width={64} height={64}
                                                                className="mb-1 img-fluid rounded-circle thumb64"
                                                                src={"data:image/png;base64," + e.thumbFile}
                                                                alt="Contact" /> :
                                                            <img width={64} height={64}
                                                                className="mb-1 img-fluid rounded-circle thumb64"
                                                                src="/Image/sem_foto.png"
                                                                alt="Contact" />}
                                                        <h6>{e.description}</h6>
                                                        {!e.duration ? null :
                                                            <em ><small><BsClockHistory />{` ${e.duration} minutos.`}</small></em>}
                                                        {e.note == undefined ? null :
                                                            <div className=''><small>{"Obs.: " + e.note}</small></div>}
                                                    </CardBody>
                                                    <CardFooter className="d-flex">
                                                        <div className="float-right">
                                                            <FormGroup
                                                                check
                                                                inline
                                                                key={e.id}
                                                            >
                                                                <Input type="checkbox"
                                                                    onChange={x => this.addService(e, x)}
                                                                    checked={e.checked ?? false}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='float-right'>{
                                                            e.value && e.displayValue > 0 ?
                                                                e.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                                                : null}
                                                        </div>
                                                    </CardFooter>
                                                </Card>
                                            </Col> : null
                                    ))}
                                </Row></div> :
                            <Row>
                                <Col>
                                    <h5 className='text-center'>Não possui serviços habilitados!</h5>
                                </Col>
                            </Row>}
                        <Row>
                            <Col>
                                <Button id='free-offer-button'
                                    disabled={listChecked.length > 0 ? false : true}
                                    color='primary'
                                    href={myParam + "/professionals"}
                                    block>
                                    Próximo
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}
