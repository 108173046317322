import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import {
    Modal, ModalBody,
} from 'reactstrap';
import "./spinner.css";
import "./modal.css"
import { FaSpinner } from 'react-icons/fa';

export const Spinner = (props) => {
    const { promiseInProgress } = usePromiseTracker({ area: props.area, delay: 0 });

    return (
        !promiseInProgress && (
            <Modal
                isOpen={true}
                className="bd-modal-dialog"
                centered={true}
                size="sm"
            ><ModalBody>
                    <FaSpinner size={30} className="icon_pulse" />
                </ModalBody>
            </Modal>
        )

    );
};

