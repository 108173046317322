import React, { Component } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Badge,
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    ModalHeader
} from 'reactstrap'
import axios from 'axios';
import { URL_PreScheduling } from '../Services/preSchedulingService';
import { useNavigate } from 'react-router-dom'
import {
    parseISO, format,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { FaSpinner } from 'react-icons/fa';
import { DatePicker } from "./DatePicker/DatePicker";
import moment from "moment";

export default class Professionals extends Component {

    constructor(props) {

        super(props)
        const myParam = window.location.pathname.split('/')[1];
        this.state = {
            listProvider: [],

            myParam: myParam,
            formDay: [],
            listDaysFunctionality: [],
            listScheduling: [],
            dateSelected: new Date(),
            loading: false,
            professionalLoading: true,
        }
    }

    componentDidMount = async () => {
        // const limitDays = localStorage.setItem("LimitPreScheduling");
        // if (limitDays != null) {
        //     this.setState({
        //         limitDays
        //     });
        // }
        if (localStorage.getItem('listProcedureId') == null) {
            this.toggle()
        } else {
            await this.consultProviders()
            await this.searchAppointmentList()
        }
    }

    consultProviders = async () => {
        // this.setState({professionalLoading:true})
        const { myParam } = this.state
        if (myParam != '') {
            await axios.get(`${URL_PreScheduling}/GetProviders`,
                {
                    params: {
                        aliasClinic: myParam,
                        listIdProcedure: localStorage.getItem('listProcedureId')
                    }
                }).then(response => {
                    const { data } = response
                    if (data != 'Clínica não localizada!')
                        this.arrayProviders = data;
                    this.setState({
                        listProvider: data,

                    })
                }).catch(() => { this.setState({ professionalLoading: false }) });
            this.setState({ professionalLoading: false })
        }
    }

    searchAppointmentList = async (date) => {
        let listIdCompanyProvider = [];
        this.arrayProviders.forEach(element => (
            listIdCompanyProvider.push(element.idCompanyProvider)
        ));

        let listProcedures = JSON.parse(localStorage.getItem('listProcedures'));
        let servicesDuration = 0;
        if (listProcedures != null && listProcedures.length > 0) {

            listProcedures.forEach(element => {
                servicesDuration += element.duration
            });
        }
        const { myParam } = this.state
        let listScheduling = [];
        await axios.get(`${URL_PreScheduling}/GetSchedulingDay/${myParam}/${servicesDuration}`, {
            params: {
                listProvidersKey: JSON.stringify(listIdCompanyProvider),
                date: date == undefined ? new Date() : date
            }
        }).then(resp => {
            const { data } = resp
            if (data.length > 0) {
                if (data) {
                    data.forEach(element => {
                        let map = {
                            hourList: element.hourList,
                            idCompanyProvider: element.idCompanyProvider,
                            day: date == undefined ? new Date() : date
                        }
                        listScheduling.push(map)
                    });

                    for (let schedule of listScheduling)
                        schedule.hourList.sort((a, b) => moment(a).diff(moment(b)));

                    this.setState({ formDay: listScheduling })
                }
            }
        });
    }

    validate = () => {
        const { model } = this.state
        const errors = {}
        let isError = 0;
        if (!model.idDoctor) {
            isError++
            errors.idDoctorError = true
        } else
            errors.idDoctorError = false
        this.setState({
            errors: errors
        });
        return isError;
    }

    setValues = async (e, field) => {
        const { model } = this.state
        if (field == 'idDoctor') {
            model[field] = e
        }
        else
            model[field] = e.target.value
        this.setState({ model })
        this.validate()
    }

    onSelect = async (d, idCompanyProvider) => {

        this.setState({ loading: true, date: d })
        await this.searchAppointmentList(d)
        this.setState({ loading: false })
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        const { listProvider, myParam, loading, professionalLoading, formDay, date, idCompanyProviderSelected } = this.state
        return (
            <div>
                <div className="text-center" >
                    {this.state.modal == false || this.state.modal == undefined ?
                        <div>
                            <h5 className='text-center'>Selecione o dia e a hora desejada:</h5>
                            {listProvider.length > 0 ?
                                <div>
                                    <Cards
                                        myParam={myParam}
                                        listProvider={listProvider}
                                        idCompanyProviderSelected={idCompanyProviderSelected}
                                        loading={loading}
                                        formDay={formDay}
                                        onSelect={this.onSelect}
                                        date={date}
                                    />
                                </div> :
                                <div>
                                    {
                                        professionalLoading && <FaSpinner className="icon_pulse text-center" />
                                    }
                                    {!professionalLoading && <h6
                                        className="text-danger mt-4"
                                    >Nenhum profissional presta todos serviços selecionados!</h6>}
                                </div>


                            } </div>
                        :
                        <Modal
                            centered={true}
                            isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
                            backdrop='static'>
                            <ModalHeader >Selecione um serviço!</ModalHeader>
                            <ModalBody>
                                É obrigatório selecionar um serviço, você será direcionado para a tela de serviços.
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" href={`/${myParam}`}>OK</Button>{' '}
                            </ModalFooter>
                        </Modal>}
                </div>
            </div>
        );
    }
}

function Cards(props) {
    const navigate = useNavigate();
    const redirectScheduling = (h, l) => {
        localStorage.setItem('idProviderSelected', l.idCompanyProvider)
        localStorage.setItem('dateTimeSelected', h)
        navigate('/' + props.myParam + '/scheduling', { replace: true })
    }
    const selectedDate = (data) => {
        props.onSelect(data)
    }
    const limitDays = localStorage.getItem("LimitPreScheduling");
    return (
        <div>
            <Row>
                {props.listProvider.map((p, index) => (
                    <Col key={p.id}
                        md={3} >
                        <Card
                            style={{ height: '100%' }}><CardBody>
                                <div >
                                    <a className="mr-2">
                                        {p.logo != null ?
                                            <img className="mb-2 img-fluid rounded-circle thumb64"
                                                width={64} height={64}
                                                src={"data:image/png;base64," + p.logo}
                                                alt="Contact" /> :
                                            <img width={64} height={64} className="mb-2 img-fluid rounded-circle thumb64"
                                                src="/Image/sem_foto.png"
                                                alt="Contact" />
                                        }
                                    </a>
                                    <div className="media-body pt-1">
                                        <h6>{p.nameCompanyProvider}</h6>
                                    </div>
                                    <Row>
                                        <Col>
                                            <div
                                            >
                                                <DatePicker
                                                    color={'#FFA500'}
                                                    getSelectedDay={e => selectedDate(e)}
                                                    labelFormat={"MMMM yyyy"}
                                                    selectDate={props.date}
                                                    days={limitDays > 0 ? limitDays : null}
                                                    id={p.id}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div>
                                        {props.loading == true ?
                                            <div className='text-center'><FaSpinner className="icon_pulse" /></div> :
                                            <div >
                                                {
                                                    props.formDay.length > 0 ?
                                                        props.formDay.map((l, index) => (
                                                            l.idCompanyProvider == p.idCompanyProvider ?
                                                                <div key={index}>
                                                                    <div className='text-center'>
                                                                        {format(l.day, 'PPPP', { locale: ptBR })}
                                                                    </div>
                                                                    <br />
                                                                    <Row >
                                                                        {

                                                                            (l.hourList.length > 0 &&
                                                                                new Date() <= parseISO(l.hourList.slice(-1)[0])) ?
                                                                                l.hourList.map((h, index) => (

                                                                                    parseISO(h) > new Date() ?
                                                                                        <Col
                                                                                            key={index}
                                                                                            xs={2}
                                                                                        >
                                                                                            <h6>
                                                                                                <Badge
                                                                                                    color="primary"
                                                                                                >
                                                                                                    <div
                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                        onClick={e => redirectScheduling(h, l)}
                                                                                                    >
                                                                                                        {format(parseISO(h, new Date()), 'HH:mm')}
                                                                                                    </div>
                                                                                                </Badge>
                                                                                            </h6> </Col>
                                                                                        : null
                                                                                ))

                                                                                : <div className='text-center mt-5'>
                                                                                    <h6 className='text-danger'>Profissional sem horário disponível nessa data!</h6></div>
                                                                        }
                                                                    </Row>
                                                                </div> : null)) : null}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}</Row>
        </div>
    );
}


