
import React, { Component } from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalHeader, ModalFooter, FormGroup, Label, NavItem, NavLink, TabPane, TabContent, Nav, FormFeedback
} from 'reactstrap'
import axios from 'axios';
import swal from 'sweetalert';
import { registerLocale } from 'react-datepicker';
import br from 'date-fns/locale/pt-BR'
import {
    format, parseISO
} from 'date-fns'
import InputMask from 'react-input-mask';
import CharacterRemover from 'character-remover';
import { URL_PreScheduling } from '../Services/preSchedulingService';
import { FaSpinner, FaRegCopy, FaCheck } from 'react-icons/fa';
import { BiRefresh } from 'react-icons/bi'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { GiCheckMark } from 'react-icons/gi'
import { Spinner } from '../../common/components/spinner/spinner';
import { useNavigate, } from 'react-router-dom';
import characterRemover from 'character-remover';

export default class FormPayment extends Component {

    constructor(props) {
        AddLibrary()
        super(props)
        registerLocale('br', br)
        const myParam = window.location.pathname.split('/')[1];
        this.state = {
            activeTab: '1',
            modelCard: { numberCard: '', cvv: '', date: '', name: '' },
            errors: {},
            loading: false,
            tooltipOpen: false,
            modelScheduling: { preSchedulingServiceProvideds: [] },
            myParam: myParam,
            redirect: false,
            modelPix: { galaxPayId: 0, reference: '', qrCode: '', image: '', createdAt: '' },
            loadingSaveWithPix: false
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
        if (tab == 2) {
            this.generateQrcode();
        }
    }

    generateQrcode = async () => {
        this.setState({ loading: true })
        const { modelCard, myParam, modelScheduling } = this.state

        modelScheduling.creditCardRequest = modelCard;
        modelScheduling.method = 'pix';
        await axios.post(`${URL_PreScheduling}/SavePaymentScheduling/${myParam}`, modelScheduling).then(resp => {
            const { data } = resp
            if (data.message != null)
                swal({ text: data.message, icon: 'warning' })
            else
                this.setState({ modelPix: data.pixData, loading: false })
        }).catch((error) => { this.setState({ loading: false }) })
        this.setState({ loading: false })
    }


    setValues = (e, field) => {
        const { modelCard } = this.state
        modelCard[field] = e.target.value
        this.setState({ modelCard })
    }

    validate = () => {
        const { modelCard } = this.state
        const errors = {}
        let isError = 0;
        const numberCard = characterRemover.removeAll(modelCard.numberCard)
        const date = characterRemover.removeAll(modelCard.date)
        if (!modelCard.name) {
            isError++
            errors.nameError = true;
        }
        else
            errors.nameError = false;

        if (!numberCard || numberCard.length < 16) {
            isError++
            errors.numberCardError = true;
        } else
            errors.numberCardError = false;

        if (!date || date.length < 6) {
            isError++
            errors.dateError = true
        }
        else
            errors.dateError = false

        if (!modelCard.cvv) {
            isError++
            errors.cvvError = true
        }
        else
            errors.cvvError = false
        this.setState({
            errors: errors
        });
        return isError;
    }

    toggleClick = () => {
        const { modelPix } = this.state
        this.setState({
            tooltipOpen: true
        })

        if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
            return navigator.clipboard.writeText(modelPix.qrCode);
        }
    }

    payment = async () => {
        if (this.validate() == 0) {
            const { modelCard } = this.state
            const token = localStorage.getItem('PublicTokenGalax')
            const inst = new window.GalaxPay(token, true)
            let card = '';
            try {
                card = await inst.newCard({
                    number: modelCard.numberCard,
                    holder: modelCard.name,
                    expiresAt: `${modelCard.date.slice(-4)}-${modelCard.date.substring(0, 2)}`,
                    cvv: modelCard.cvv
                })

            } catch (error) {
                swal({ text: "Verifique as informações do cartão!", icon: 'warning' })
                return
            }
            new Promise((resolve) => {
                this.setState({ loading: true })
                inst.hashCreditCard(
                    card,
                    function (hash) {
                        if (hash)
                            resolve(hash)
                    }, function (error) {
                        resolve(error)
                        swal({ text: "Verifique as informações do public Token!", icon: 'warning' })

                    });
            }).then(async (value) => {

                const { myParam, modelScheduling } = this.state
                modelScheduling.hashCard = value;
                modelScheduling.method = 'creditcard';
                await axios.post(`${URL_PreScheduling}/SavePaymentScheduling/${myParam}`, modelScheduling).then(resp => {
                    const { data } = resp
                    if (data.message == "Salvo com sucesso!")
                        this.setState({ redirect: true, loading: false })
                    else
                        swal({ text: data.message, icon: 'warning' })
                }).catch((error) => {
                    console.log("error encripto", error)
                    this.setState({ loading: false })
                })

                this.setState({ loading: false })
            })
        }
    }


    SearchPaymentAndSaveSchedule = async () => {
        // bater na api se achar o pagamento salva o agendamento
        this.setState({ loadingSaveWithPix: true })
        const { modelPix, myParam, modelScheduling } = this.state

        modelScheduling.pixRequest = modelPix
        await axios.post(`${URL_PreScheduling}/SearchPaymentAndSaveSchedule/${myParam}`, modelScheduling).then(resp => {
            const { data } = resp
            if (data.message == "Salvo com sucesso!")
                this.setState({ redirect: true, loadingSaveWithPix: false })
            else {
                swal({ text: data.message, icon: 'warning' })
            }

        }).catch((error) => { this.setState({ loadingSaveWithPix: false }) })
        this.setState({ loadingSaveWithPix: false })
    }

    componentDidMount() {
        this.setState({ modelScheduling: JSON.parse(localStorage.getItem('mapscheduling')) })

    }

    render() {
        const { errors, loading, tooltipOpen, loadingSaveWithPix,
            modelScheduling, redirect, myParam, modelPix } = this.state
        const value = modelScheduling.preSchedulingServiceProvideds.reduce((partialSum, a) => partialSum + a.value, 0);
        return (
            <div className='card'>

                <div className="card-header">
                    Escolha a forma de pagamento
                </div>
                {redirect && <Redirect myParam={myParam} tel={modelScheduling.telephone} />}
                {loading && <Spinner />}
                <div className='card-body'>
                    <Nav
                        className='mr-auto'
                        pills>
                        <NavItem>
                            <NavLink
                                active={this.state.activeTab === '1' ? true : false}
                                onClick={() => { this.toggle('1'); }}
                            >
                                Cartão
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={this.state.activeTab === '2' ? true : false}
                                onClick={() => { this.toggle('2'); }}
                            >
                                Pix
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1" className='mt-1'>
                            <table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Nº de Parcelas</th>
                                        <th>Forma de Pagamento</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>À vista</td>
                                        <td><span className="no-break-word"> Crédito</span></td>
                                        <td className="td-value-with-discount"><div data-class-td="td-without-padding">
                                        </div><div className="td-value-transaction">{value.toLocaleString('pt-br',
                                            { style: 'currency', currency: 'BRL' })}</div></td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                            <div className='row'>
                                <FormGroup >
                                    <Label>Número do cartão*</Label>
                                    <Input
                                        type='text'
                                        mask='9999 9999 9999 9999'
                                        tag={InputMask}
                                        onChange={e => this.setValues(e, 'numberCard')}
                                        placeholder='Número'
                                        invalid={errors.numberCardError}
                                    >
                                    </Input>
                                    <FormFeedback>Campo obrigatório</FormFeedback>
                                </FormGroup>
                                <FormGroup >
                                    <Label>Nome no cartão*</Label>
                                    <Input
                                        type='text'
                                        onChange={e => this.setValues(e, 'name')}
                                        placeholder='Nome'
                                        invalid={errors.nameError}
                                    >
                                    </Input>
                                    <FormFeedback>Campo obrigatório</FormFeedback>
                                </FormGroup>
                                <FormGroup >
                                    <Label>Validade*</Label>
                                    <Input
                                        mask='99-9999'
                                        tag={InputMask}
                                        placeholder='Mês/Ano'
                                        onChange={e => this.setValues(e, 'date')}
                                        invalid={errors.dateError}
                                    >
                                    </Input>
                                    <FormFeedback>Campo obrigatório</FormFeedback>
                                </FormGroup>
                                <FormGroup >
                                    <Label>Código de segurança*</Label>
                                    <Input
                                        type='number'
                                        placeholder='Código de verificação'
                                        invalid={errors.cvvError}
                                        onChange={e => this.setValues(e, 'cvv')}
                                    >
                                    </Input>
                                    <FormFeedback>Campo obrigatório</FormFeedback>
                                </FormGroup>
                            </div>
                            <Button block color='primary'
                                onClick={e => this.payment()}
                            >Realizar o pagamento</Button>
                        </TabPane>
                        <TabPane tabId="2">

                            {loading == false && modelPix.galaxPayId > 0 &&
                                <div >
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='row'>
                                                <div >Valor a ser pago:
                                                    <h5 className='text-warning'>{value.toLocaleString('pt-br',
                                                        { style: 'currency', currency: 'BRL' })}</h5></div>
                                            </div>
                                            <div className='row'>
                                                <p>Expiração do QR Code:
                                                    <strong>{" " + format(parseISO(modelPix.createdAt, new Date()), 'dd-MM-yyyy HH:mm')}
                                                    </strong></p>
                                            </div>
                                            <div className='row'>
                                                <p>Identificador:
                                                    <strong>{" " + modelPix.reference}</strong></p>
                                            </div>
                                        </div>

                                        <div className='col-md-3'>
                                            {
                                                new Date() <
                                                    parseISO(modelPix.createdAt, new Date()) ?
                                                    <div className='row'>
                                                        <Button
                                                            className='btn btn-warning'
                                                            size='sm'
                                                            color='warning'
                                                            onClick={e => this.toggleClick()}
                                                        >{tooltipOpen ? <div><FaCheck />COPIADO</div>
                                                            : <div><FaRegCopy />COPIAR CÓDIGO QR</div>}
                                                        </Button>
                                                        <div style={{ border: 'solid', borderRadius: '5px' }}>
                                                            <img
                                                                src={modelPix.image}
                                                                loading="lazy" alt="" style={{
                                                                    "maxWidth": "100%",
                                                                    "height": "auto"
                                                                }} />
                                                        </div>
                                                    </div> :
                                                    <div className='row'>
                                                        <Button
                                                            outline color="danger"
                                                            onClick={e => this.generateQrcode()}
                                                        >
                                                            <BiRefresh />
                                                            Atulizar QR code
                                                        </Button>
                                                    </div>}
                                        </div>
                                    </div>
                                    <br />
                                    <Button
                                        color='success'
                                        block
                                        onClick={e => this.SearchPaymentAndSaveSchedule()}
                                        disabled={loadingSaveWithPix}
                                    >
                                        {loadingSaveWithPix && (
                                            <FaSpinner className="icon_pulse" />
                                        )}
                                        {!loadingSaveWithPix &&
                                            <span> Agendar</span>
                                        }
                                    </Button>
                                </div>}
                            {loading == false && modelPix.galaxPayId == 0 && <div><IoAlertCircleOutline fontSize={40} color='orange' /> Pix indisponível no momento</div>}
                            {loading && ""}
                        </TabPane>
                    </TabContent>

                </div>
            </div>
        );
    }
}

function Redirect(props) {
    const navigate = useNavigate()
    let tel = CharacterRemover.removeAll(props.tel)
    const redirectSearchScheduling = () => {
        navigate('/' + props.myParam + '/schedulingssearch/' + tel, { replace: true })
    }

    return (
        <Modal
            backdrop='static'
            isOpen={true} fade={false}>
            <ModalHeader >Salvo</ModalHeader>
            <ModalBody>
                <div className='text-center'>
                    <p>   <GiCheckMark color='green' size={40} /></p>
                    Agendamento salvo com sucesso!</div></ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={e => redirectSearchScheduling()}>OK</Button>{' '}
            </ModalFooter>
        </Modal>
    )
}

export function AddLibrary() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.galaxpay.com.br/checkout.min.js';
    script.async = true;
    document.body.appendChild(script);
}
