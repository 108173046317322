import React, { Component } from 'react';

import Header from './Header'

import axios from 'axios';
import { URL_PreScheduling } from '../Services/preSchedulingService';
import './body.css'
class Base extends Component {

    constructor(props) {
        super(props)
        const myParam = window.location.pathname.split('/')[1];
        this.state = {
            loading: true,
            myParam: myParam,
            modelClinic: {},
            establishmentLoaded: false
        }
    }

    componentDidMount = async () => {
        await this.consultClinic()
    }

    consultClinic = async () => {
        const { myParam } = this.state
        if (myParam != '') {
            await axios.get(`${URL_PreScheduling}/GetClinicById/${myParam}`).then(resp => {
                const { data } = resp
                if (data == 'Clínica não localizada!')
                    throw new Error('I crashed!');
                else if (data == "Empresa desativada.") { }
                else {
                    console.log('passou', data.typeOfRedirectInWebScheduling);
                    localStorage.setItem("PaymentRequired", data.paymentRequired)
                    localStorage.setItem("CpfRequired", data.cpfRequired)
                    localStorage.setItem("PublicTokenGalax", data.publicTokenGalax)
                    localStorage.setItem("TypeOfRedirect", data.typeOfRedirectInWebScheduling)
                    localStorage.setItem("LimitPreScheduling", data.limitPreScheduling)
                    this.setState({ modelClinic: data, establishmentLoaded: true })
                }
            }).catch(() => { throw new Error(); })

        }
        else
            this.setState({ establishmentLoaded: false })
    }

    render() {
        const { modelClinic, establishmentLoaded } = this.state
        return (
            <div className="body">
                <Header modelClinic={modelClinic}
                    establishmentLoaded={establishmentLoaded} />
                {establishmentLoaded ?
                    <div>
                        <hr />
                        <section className="section-container">
                            {this.props.children}
                        </section></div> : null}
                {/* <Footer /> */}
            </div>
        );
    }
}
export default Base;
