import React, { Component } from "react";
import { Route, Routes, } from "react-router-dom";
import Scheduling from './Components/Scheduling/Scheduling'
import SchedulingsSearch from "./Components/SchedulingsSearch/SchedulingsSearch";
import Base from "./Components/Layout/Base"
import SelectServices from "./Components/SelectServices/SelectServices";
import Professionals from "./Components/Professionals/Professionals";
import BasePage from "./Components/Layout/BasePage";
import Anamnese from "./Components/Anamnese/Anamnese";
import ErrorBoundary from './Components/Errors/ErrorBoundary';
import OptionsInitial from "./Components/OptionsInitial/OptionsInitial";
import FormPayment from "./Components/Payment/FormPayment";
import Plans from "./Components/Payment/PaymentPlans";

const Router = () => {
    const currentKey = window.location.pathname.split('/')[1] || '/';
    if (currentKey == "paymentplans") {
        return (
            <BasePage>
                <Routes>
                    {/* <Route path="/anamnese/:guid" element={<Anamnese />} /> */}
                    <Route path="/paymentplans" index element={<Plans />} />
                </Routes>
            </BasePage>
        )
    } else {
        return (
            <ErrorBoundary>
                <Base>
                    <Routes>
                        {/* <Route path="/:param1" index element={<Loading />} /> */}
                        {/* <Route path="/:param1" index element={<OptionsInitial />} /> */}
                        <Route path="/:param1" index element={<SelectServices />} />
                        <Route path="/:param1/scheduling" element={<Scheduling />} />
                        <Route path="/:param1/schedulingssearch" element={<SchedulingsSearch />} />
                        <Route path="/:param1/schedulingssearch/:tel" element={<SchedulingsSearch />} />
                        <Route path="/:param1/professionals" element={<Professionals />} />
                        <Route path="/:param1/formpayment" element={<FormPayment />} />
                        
                    </Routes>
                </Base>
            </ErrorBoundary>
        )
    }
}
export default Router;

export class Loading extends Component {
    render() {
        return (<div className="text-center mt-5">Carregando...</div>)
    }
}