import React, { Component } from "react";
import {
    Button,
    Input,
    Modal,
    ModalBody,
    Row,
    Col,
    ModalHeader,
} from 'reactstrap'
export default class OptionsInitial extends Component {
    constructor(props) {
        super(props)
        const myParam = window.location.pathname.split('/')[1];
        this.state = {

            myParam: myParam,

        }
    }
    render() {
        const { myParam } = this.state
        return (
            <div >
                <div className="card-body" >
                    <div className="text-center"><h5>Por onde gostaria de começar?</h5></div>
                    <br />
                    <Row>
                        <Col md={6}>
                            <Button color="primary" size="lg" href={myParam + "/selectservices"} block>Serviços</Button>
                        </Col>
                        <Col md={6}>
                            <Button color="secondary" size="lg" block>Profissionais</Button>
                        </Col>
                    </Row></div>


            </div>);
    }
}