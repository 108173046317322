import moment from 'moment';
import React, { Component } from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    Row,
    ModalHeader,
    CardBody,
    Card,
    CardHeader, ModalFooter,
} from 'reactstrap'
import axios from 'axios';
import swal from 'sweetalert';
import { registerLocale } from 'react-datepicker';
import br from 'date-fns/locale/pt-BR'
import {
    format, parse
} from 'date-fns'
import "react-datepicker/dist/react-datepicker.css";
import InputMask from 'react-input-mask';
import CharacterRemover from 'character-remover';
import validator from 'validator';
import { URL_PreScheduling } from '../Services/preSchedulingService';
import '@fortawesome/free-solid-svg-icons'
import '@fortawesome/free-regular-svg-icons'
import 'react-day-picker/dist/style.css';
import { FaSpinner } from 'react-icons/fa';
import { GiCheckMark } from 'react-icons/gi'
import { Spinner } from '../../common/components/spinner/spinner';
import { useNavigate, } from 'react-router-dom';
import { validate } from 'gerador-validador-cpf'
import { enumTypeRedirect } from '../Utils/enumTypeRedirect';

class Scheduling extends Component {

    constructor(props) {
        super(props)
        registerLocale('br', br)
        const myParam = window.location.pathname.split('/')[1];

        this.state = {
            model: {
                name: '', telephone: '', email: '', date: ''
                , idCompanyProvider: 0, nameProvider: '', note: '',
                birthDate: '', document: ''
            },
            errors: {},
            listDoctor: [],
            modelClinic: {},
            listSchedulings: [],
            clinicLocated: true,
            myParam: myParam,
            dateSelected: '',
            listProcedures: [],
            loading: false,
            servicesDurationSum: 0,
            loadingSearchClient: false,
            redirect: false,
            paymentRequired: false,
            cpfRequired: false,
            TypeOfRedirect: 0
        }
    }

    validateFields = () => {

        const { model, paymentRequired, cpfRequired } = this.state
        const errors = {}
        let isError = 0;
        let phone = CharacterRemover.removeAll(model.telephone)
        let document = CharacterRemover.removeAll(model.document)
        let email = validator.isEmail(model.email)
        var result = parse(model.birthDate, 'dd/MM/yyyy', new Date())
        if (!model.name) {
            isError++
            errors.nameError = true;
        }
        else
            errors.nameError = false;
        if (!phone || phone.length < 11) {
            isError++
            errors.telephoneError = true;
        } else
            errors.telephoneError = false;

        if (model.email != '' && email == false) {
            isError++
            errors.emailError = true
        }
        else
            errors.emailError = false
        if ((paymentRequired == true || cpfRequired == true) && validate(document) == false) {
            isError++
            errors.documentError = true
        }
        else
            errors.documentError = false
        if (model.birthDate != '' && result == 'Invalid Date') {
            isError++
            errors.birthDateError = true
        } else {
            errors.birthDateError = false
        }
        this.setState({
            errors: errors
        });
        return isError;
    }

    setValues = async (e, field) => {
        const { model } = this.state
        if (field == 'date')
            model[field] = e
        else
            model[field] = e.target.value
        this.setState({ model })
        var result = parse(model.birthDate, 'dd/MM/yyyy', new Date())
        console.log(model.birthDate, result);
        this.validateFields()
    }

    clearModel() {
        localStorage.clear();
        this.setState({
            listProcedures: [],
            servicesDurationSum: 0,
            redirect: true
        })
    }



    toSchedule = async () => {
        if (this.validateFields() == 0) {
            this.setState({ loading: true })
            const { model, myParam, listProcedures } = this.state
            const tel = CharacterRemover.removeAll(model.telephone)

            let map = {
                idCompanyProvider: model.idCompanyProvider,
                name: model.name,
                telephone: tel,
                email: model.email,
                date: format(model.date, "yyyy-MM-dd HH:mm"),
                note: model.note,
                preSchedulingServiceProvideds: listProcedures,
                consultationTime: listProcedures.reduce((partialSum, a) => partialSum + a.duration, 0),
                birthDate: model.birthDate == '' ? null : parse(model.birthDate, 'dd/MM/yyyy', new Date()),
                document: CharacterRemover.removeAll(model.document),
                createdDate: format(moment().toDate().getTime(), "yyyy-MM-dd HH:mm")
            }

            await axios.post(`${URL_PreScheduling}/${myParam}`, map).then(resp => {
                const { data } = resp

                if (data.message == "Salvo com sucesso!") {
                    if (data.procedureClient != undefined && data.procedureClient.length > 0) {
                        swal({
                            buttons: true,
                            icon: 'warning',
                            text: `Pontuação atingida. Deseja Resgatar?'\n
                                Serviços: ${data.procedureClient.map(r => (r.description))}`
                        }).then(resp => {
                            if (resp) {
                                axios.put(`${URL_PreScheduling}/RedeemPoints`, data).then(resp => {
                                    const { data } = resp
                                    if (data) {

                                        this.setState({ loading: false })
                                        this.clearModel()
                                    }
                                })
                            } else {
                                this.setState({ loading: false })
                                this.clearModel()
                            }
                        })
                    } else {
                        this.setState({ loading: false })
                        this.clearModel();
                    }
                }
                else {
                    swal({
                        icon: 'error',
                        text: 'Horário indisponível!'
                    }).then(resp => {
                        this.setState({ loading: false })
                    })
                }
            }).catch(() => this.setState({ loading: false }))
        }
    }
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    componentDidMount = () => {
        const { model } = this.state
        let list = JSON.parse(localStorage.getItem('listProcedures'));
        model.idCompanyProvider = localStorage.getItem("idProviderSelected")
        let PaymentRequired = localStorage.getItem('PaymentRequired') == 'true' ? true : false;
        let CpfRequired = localStorage.getItem('CpfRequired') == 'true' ? true : false;
        model.date = localStorage.getItem('dateTimeSelected') != null ?
            Date.parse(localStorage.getItem('dateTimeSelected')) : ''
        let TypeOfRedirect = localStorage.getItem("TypeOfRedirect")
        this.setState({
            model: model, listProcedures: list,
            paymentRequired: PaymentRequired,
            cpfRequired: CpfRequired,
            TypeOfRedirect: TypeOfRedirect
        })
    }

    consultClient = async () => {
        const { model, myParam } = this.state
        this.setState({ loadingSearchClient: true })
        const tel = CharacterRemover.removeAll(model.telephone)

        if (tel && tel.length >= 11) {
            await axios.get(`${URL_PreScheduling}/GetClientByPhone`, {
                params: {
                    aliasClinic: myParam,
                    phone: tel
                }
            }).then(resp => {
                const { data } = resp;

                if (data.id > 0) {
                    model.telephone = data.cellPhone;
                    model.name = data.name;
                    model.document = data.cpf
                    this.setState({ model })
                }
            }).catch(() => { this.setState({ loadingSearchClient: false }) })
        }
        this.setState({ loadingSearchClient: false })
    }
    render() {
        const { model, errors, loadingSearchClient,
            loading, paymentRequired, cpfRequired,
            myParam, redirect, listProcedures, TypeOfRedirect } = this.state
        return (
            <div >
                {redirect && <Redirect myParam={myParam} tel={model.telephone}
                    TypeOfRedirect={TypeOfRedirect} />}
                {loadingSearchClient && <Spinner />}
                <Card>
                    <CardHeader tag={'h5'}><small>Preencha os campos para agendar:</small> {model.date != '' ?
                        (format(model.date, 'cccc HH:mm', { locale: br })) : ''}</CardHeader>

                    <CardBody>
                        <div className='form-group border-0'>
                            <Row>
                                <div className='col-md-6'>
                                    <label>Telefone*:</label>
                                    <Input type="text"
                                        mask='(99) 9 9999-9999'
                                        tag={InputMask}
                                        onChange={e => { this.setValues(e, 'telephone'); this.consultClient() }}
                                        value={model.telephone}
                                        placeholder="Telefone"
                                        invalid={errors.telephoneError}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <label>Nome*:</label>
                                    <Input
                                        type="text"
                                        onChange={e => this.setValues(e, 'name')}
                                        value={model.name ?? ''}
                                        placeholder="Nome completo"
                                        invalid={errors.nameError}
                                    />
                                </div>

                            </Row>
                            <Row>
                                {paymentRequired == true || cpfRequired == true ? (
                                    <div className='col-md-6'>
                                        <label>Cpf*:</label>
                                        <Input
                                            type="text"
                                            onChange={e => this.setValues(e, 'document')}
                                            value={model.document}
                                            mask='999.999.999-99'
                                            tag={InputMask}
                                            invalid={errors.documentError}
                                        />
                                    </div>) : null}
                                <div className='col-md-6'>
                                    <label>Email:</label>
                                    <Input type="email"
                                        value={model.email}
                                        invalid={errors.emailError}
                                        onChange={e => this.setValues(e, 'email')}
                                        placeholder="Email" />
                                </div>

                            </Row>
                            <Row>
                                <div className='col-md-6'>
                                    <label>Observação</label>
                                    <Input type="text"
                                        value={model.note}

                                        onChange={e => this.setValues(e, 'note')}
                                        placeholder="Observação" />
                                </div>
                                <div className='col-md-6'>
                                    <label>Data Nascimento:</label>
                                    <Input type="text"
                                        mask='99/99/9999'
                                        tag={InputMask}
                                        value={model.birthDate}
                                        onChange={e => this.setValues(e, 'birthDate')}
                                        invalid={errors.birthDateError}
                                    />
                                </div>
                            </Row>
                            <p className="float-right text-sm">
                                <i>Os campos marcados com (*) são obrigatórios.</i>
                            </p>
                        </div>
                        {paymentRequired == false ?
                            <Button
                                color='success'
                                size='lg'
                                block
                                onClick={e => this.toSchedule()}
                                disabled={loading}
                            >
                                {loading && (
                                    <FaSpinner className="icon_pulse" />
                                )}
                                {!loading && <span> Agendar</span>}
                            </Button> :
                            <RedirectPayment
                                model={model}
                                listProcedures={listProcedures}
                                myParam={myParam}
                                validateFields={this.validateFields}
                            />}
                    </CardBody>
                </Card>
            </div>

        );
    }
}

export default class SchedulingClient extends Component {
    render() {
        return (
            <div>
                <Scheduling />
            </div>
        )
    }
}

function Redirect(props) {
    const navigate = useNavigate()
    let tel = CharacterRemover.removeAll(props.tel)
    const redirectSearchScheduling = () => {
        if (props.TypeOfRedirect == enumTypeRedirect.selectservice)
            navigate('/' + props.myParam, { replace: true })
        else
            navigate('/' + props.myParam + '/schedulingssearch/' + tel, { replace: true })
    }

    return (
        <Modal
            backdrop='static'
            isOpen={true} fade={false}>
            <ModalHeader >Salvo</ModalHeader>
            <ModalBody>
                <div className='text-center'>
                    <p>   <GiCheckMark color='green' size={40} /></p>
                    Agendamento salvo com sucesso!</div></ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={e => redirectSearchScheduling()}>OK</Button>{' '}
            </ModalFooter>
        </Modal>
    )
}

function RedirectPayment(props) {
    const navigate = useNavigate()
    const val = () => {
        if (props.validateFields() == 0) {
            const tel = CharacterRemover.removeAll(props.model.telephone)
            let map = {
                idCompanyProvider: props.model.idCompanyProvider,
                name: props.model.name,
                telephone: tel,
                email: props.model.email,
                date: format(props.model.date, "yyyy-MM-dd HH:mm"),
                note: props.model.note,
                preSchedulingServiceProvideds: props.listProcedures,
                consultationTime: props.listProcedures.reduce((partialSum, a) => partialSum + a.duration, 0),
                birthDate: props.model.birthDate == '' ? null : props.model.birthDate,
                document: CharacterRemover.removeAll(props.model.document)
            }
            localStorage.setItem('mapscheduling', JSON.stringify(map))
            navigate('/' + props.myParam + '/formpayment', { replace: true })
        }
    }

    return (
        <Button color="primary" block size='lg' onClick={e => val()}>Próximo</Button>
    )
}



